import React, { Component } from "react";
import { Provider } from "react-redux";
import {
  HashRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./scss/style.scss";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "src/components/ErrorBoundary";
import { useHistory } from "react-router-dom";
import routes from "./routes";
import SendEmail from "./views/pages/sendEmail";
import { persistor, store } from "./app/store";
import "./app/global.css";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

const ResetPassword = React.lazy(() => import("./views/pages/resetPassword"));

// Email App
const TheEmailApp = React.lazy(() => import("./views/apps/email/TheEmailApp"));

// Pages
const Login = React.lazy(() => import("./views/pages/login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App: React.FC = () => {
  const history = useHistory();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/alter-password/:token"
                  name="Alterar Senha"
                  render={(props) => <ResetPassword {...props} />}
                />
                <Route
                  exact
                  path="/reset-password"
                  name="Recuperar Senha"
                  render={(props) => <SendEmail {...props} />}
                />

                <Route
                  path="/"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
              </Switch>
            </React.Suspense>
          </ErrorBoundary>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
